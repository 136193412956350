// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-brand-tsx": () => import("./../src/pages/about/brand.tsx" /* webpackChunkName: "component---src-pages-about-brand-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-cards-tsx": () => import("./../src/pages/cards.tsx" /* webpackChunkName: "component---src-pages-cards-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contests-tsx": () => import("./../src/pages/contests.tsx" /* webpackChunkName: "component---src-pages-contests-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-modal-tsx": () => import("./../src/pages/modal.tsx" /* webpackChunkName: "component---src-pages-modal-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-stake-tsx": () => import("./../src/pages/stake.tsx" /* webpackChunkName: "component---src-pages-stake-tsx" */),
  "component---src-pages-team-tsx": () => import("./../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-match-tsx": () => import("./../src/templates/match.tsx" /* webpackChunkName: "component---src-templates-match-tsx" */)
}

